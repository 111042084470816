import React, {useState} from "react";
import {Stepper} from "../stepper/stepper";

import "./home-section.scss";
import {Button} from "../button/button";
import {Link} from "gatsby";
import arrowIcon from "../../images/arrow.svg";
import handleViewport from "react-in-viewport";

const HomeSectionRaw = ({steps = [], sectionData = {}, step, id, inViewport, forwardedRef, style}) => {
  const [show, setShow] = useState(false);
  if (inViewport && !show) {
    setShow(true);
  }

  return (
    <div className="my-home-section" id={id} ref={forwardedRef} style={style}>
      <Stepper steps={steps} currentPage="/" selected={step}/>
      <picture>
        <source media="(min-width:480px)" srcSet={sectionData.homeLargeBannerWP} type="image/webp" />
        <source media="(min-width:480px)" srcSet={sectionData.homeLargeBanner} />
        <source srcSet={sectionData.homeBannerWP} type="image/webp" />
        <img loading="lazy" className="my-home-section__img" src={sectionData.homeBannerImage} alt="Boat banner" />
      </picture>
      <div className={`my-home-section-content ${show ? "my-home-section-content--show" : ""}`}>
        <h2 className="my-home-section__title">{sectionData.title}</h2>
        <Button className="my-home-section__action" text="Learn more" url={sectionData.url}/>
      </div>
      <Link className="my-home-section__arrow" to={`#${steps[step+1]?.url || ""}`}>
        <img src={arrowIcon} alt="Go down"/>
      </Link>
    </div>
  );
}

export const HomeSection = handleViewport(HomeSectionRaw, {rootMargin: "-300px"}, { disconnectOnLeave: true });
