import * as React from "react"

import "./index.scss";
import SEO from "../components/SEO/SEO";
import { Layout } from "../components/layout/layout";
import { HomeSection } from "../components/home-section/home-section";
import { HOME_STEPS, PAGES_DATA } from "../utils/constants";

const IndexPage = () => {
  let stepIndex = 0;
  const pagesData = PAGES_DATA.slice(2, 8);
  return (
    <main>
      <Layout>
        <SEO />
        {pagesData.map((data) => {
          const display = !!HOME_STEPS.find(step => step.name === data.id);
          if (display) stepIndex++;
          // img hidden elements are used to preload other pages' banner images
          return (
            <div key={data.id} style={{ width: "100%", display: "flex" }}>
              <HomeSection
                key={data.id}
                sectionData={data}
                title={data.title}
                steps={HOME_STEPS}
                step={stepIndex}
                id={data.id}
                style={{ display: display ? "inherit" : "none" }} />
              <img key={`${data.id}_small`} src={data.bannerWP} alt={data.title} style={{ display: "none" }} />
              <img key={`${data.id}_large`} src={data.largeBannerWP} alt={data.title} style={{ display: "none" }} />
            </div>
          );
        })}
      </Layout>
    </main>
  )
}

export default IndexPage;
